export const TR_MAX_WEIGHT = 'TR_MAX_WEIGHT';
export const TR_MIN_WEIGHT = 'TR_MIN_WEIGHT';
export const TR_TOTAL_WEIGHT = 'TR_TOTAL_WEIGHT';
export const TR_TOTAL_QTY = 'TR_TOTAL_QTY';
export const TR_MIN_QTY = 'TR_MIN_QTY';
export const TR_FTL_CUSTOMER = 'FTL';
export const TR_LTL_CUSTOMER = 'LTL';
export const TR_UOM_WITH_FULL_ORDER = 'TR_UOM_WITH_FULL_ORDER';
export const TR_B2B_CUSTOMER_TYPE_ATTRIBUTE_NAME = 'TRB2BCustomerType';
export const TR_B2B_CUSTOMER_SHIP_WITH_ATTRIBUTE_NAME = 'TRB2BCustShipWith';

export const TR_PONUMBER_ATTRIBUTE_NAME = 'TR_PONUMBER';

export const TR_TRUCKS_ON_CART = 'TR_TRUCKS_ON_CART';
export const TR_ACTIVE_TRUCK = 'TR_ACTIVE_TRUCK';
export const TR_TRUCK_NUMBER = 'TR_TRUCK_NUMBER';

export const TR_PO_NUMBER_TRUCK_PREFIX = 'TR_PO_NUMBER_TRUCK_';
export const TR_NOTES_TRUCK_PREFIX = 'TR_NOTES_TRUCK_';
export const TR_DELIVERY_DATE_TRUCK_PREFIX = 'TR_DELIVERY_DATE_TRUCK_';

export const TR_IS_APPROVED_ADDRESS = 'TR_IS_APPROVED_ADDRESS';
